import { Box } from "@mui/material";
import React from "react";
import { CustomDataGrid } from "../Pages/Pipeline";
import { renderCell } from "./ExpandView/ExpandOpportunityView";
import Loader from "./Loader";
import EditCell from "./Table/EditCell";
import ViewCell from "./Table/ViewCell";

function EditOrViewCell(
  cellData,
  data,
  tableName,
  onUpdateSuccessOfRow,
  rowData,
  setRowData,
  searchView,
  error,
  handleError,
  relatedTableLoader,
  setRelatedTableLoader,
  expandViewFirst,
  taskView,
  editTask,
  taskViewOnly
) {
  const ViewBox = () => {
    return (
      <Box
        position="relative"
        width={
          searchView === "TaskView" || tableName === "Task"
            ? tableName === "Task"
              ? "228px"
              : "244px"
            : data.metric_datatype === "reference"
            ? "97%"
            : "95%"
        }
        height={tableName === "Task" ? "auto" : "100%"}
        display="flex"
        alignItems={"center"}
        rowId={cellData.hasFocus ? "focused-none-editable-cell" : ""}
        border={
          data.metric_datatype === "boolean" ? "none" : "1px solid #c1c1c1"
        }
        borderRadius="8px"
        padding={
          data.metric_datatype === "boolean"
            ? "0px"
            : data.metric_datatype === "reference"
            ? "4px"
            : "8px"
        }
        fontSize="14px"
        backgroundColor={data.metric_datatype === "boolean" ? "" : "#edf2fa"}
      >
        <ViewCell
          value={
            cellData.fieldType === "reference" ||
            (data?.metric_datatype === "reference" &&
              cellData.row[cellData.field] !== null)
              ? cellData?.row[data?.parentSobject]?.Name || cellData.value
              : cellData.value
          }
          nullable={data.nullable}
          cellData={cellData}
          dataType={data.metric_datatype}
          expandViewCell={true}
          onChangeHandler={(value, fieldName) => {
            searchView === "TaskView" && onUpdateSuccessOfRow(value, fieldName);
          }}
          taskView={searchView}
          taskViewOnly={taskViewOnly}
        />
      </Box>
    );
  };
  const EditBox = () => {
    return (
      <Box key={"data_field_" + cellData.value}>
        <EditCell
          onUpdateSuccess={(value, fieldName, required, whoOrWhatName) => {
            onUpdateSuccessOfRow(value, fieldName, required, whoOrWhatName);
          }}
          rawData={data}
          cellData={cellData}
          tableName={tableName}
          expandViewCell={true}
          searchView
          error={error}
          handleError={handleError}
          editTask={editTask}
          taskViewOnly={taskViewOnly}
        />
      </Box>
    );
  };

  const TableBox = () => {
    const eachTableData = rowData?.related_table_data?.filter((data) => {
      if (data.hasOwnProperty(cellData.relatedListId)) return data;
    });
    const eachTableHeader = JSON.parse(
      localStorage.getItem("relatedTablesHeaders")
    );

    const columns =
      Object.keys(eachTableHeader)?.length &&
      eachTableHeader[data.metric_name]?.length
        ? eachTableHeader[data.metric_name]
            .filter((data) => data.selected)
            .map((data) => {
              return {
                field: data.metric_name,
                headerName: data.metric_display_name,
                description: data.metric_display_name,
                width:
                  data.metric_datatype === "textarea"
                    ? 300
                    : data.metric_datatype === "boolean" ||
                      data.metric_datatype === "currency" ||
                      data.metric_datatype === "percent"
                    ? 150
                    : data.metric_datatype === "datetime"
                    ? 210
                    : data.metric_datatype === "id"
                    ? 175
                    : data.metric_display_name.length > 15
                    ? 250
                    : 190,
                sortable: true,
                renderCell: (cellData) => renderCell(cellData, data),
              };
            })
        : [];

    const rows = eachTableData?.length
      ? eachTableData[0][data.metric_related_listId]
      : [];

    return !relatedTableLoader ? (
      <Box
        sx={{
          height: rows.length ? 150 : 100,
          width: "100%",
          zIndex: 0,
        }}
      >
        <CustomDataGrid
          rows={rows}
          columns={columns}
          getRowId={(e, index) => e.Id === index}
          columnThreshold={0}
          disableColumnMenu
          hideFooter
          showColumnRightBorder={true}
          showCellRightBorder={true}
          rowHeight={35}
          headerHeight={40}
          sx={{
            "&.MuiDataGrid-root": {
              zIndex: 0,
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#D7EBF7",
                fontWeight: 600,
                fontSize: "14px",
              },
              ".MuiDataGrid-cell": {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100%",
              },
            },
          }}
        />
      </Box>
    ) : (
      <Loader></Loader>
    );
  };

  if (cellData.related) {
    return TableBox();
  } else if (cellData.isEditable) {
    if (searchView) {
      return (
        <Box
          key={"data_field" + cellData.value}
          flex={searchView === "TaskView" && 2}
          width={tableName === "Task" ? "244px" : "auto"}
        >
          <EditCell
            onUpdateSuccess={(value, fieldName, required, name) => {
              onUpdateSuccessOfRow(value, fieldName, required, name);
            }}
            rawData={data}
            cellData={cellData}
            tableName={tableName}
            expandViewCell={true}
            searchView={searchView}
            error={error}
            handleError={handleError}
            expandViewFirst={expandViewFirst}
            editTask={editTask}
            searchViews={true}
            taskViewOnly={taskViewOnly}
          />
        </Box>
      );
    } else
      return (
        <Box
          key={"data_field__" + cellData.value}
          width={
            expandViewFirst && cellData.fieldType === "reference"
              ? "auto"
              : "100%"
          }
        >
          <EditCell
            onUpdateSuccess={(value) => {
              const _rowData = { ...rowData };
              _rowData[cellData.field] = value;
              cellData.value = value;
              setRowData(_rowData);
              onUpdateSuccessOfRow();
            }}
            rawData={data}
            cellData={cellData}
            tableName={tableName}
            expandViewCell={true}
            editTask={editTask}
          />
        </Box>
      );
  } else {
    return searchView && searchView !== "TaskView" ? EditBox() : ViewBox();
  }
}

export default EditOrViewCell;
